.qualifications__container {
    max-width: 768px;
}

.qualifications__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: var(--mb-2);
}

.qualifications__button {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    color: var(--text-color);
    margin: 0 var(--mb-1);
    cursor: pointer;
}

.qualifications__button:hover {
    color: var(--title-color-dark);
}

.qualifications__icon {
    font-size: 1.8rem;
    margin-right: var(--mb-0-25);
}

.qualifications__active {
    color: var(--title-color-dark);
}

.qualifications__sections {
    display: grid;
    grid-template-columns: 0.5fr;
    justify-content: center;
}

.qualifications__content {
    display: none;
}

.qualifications__content-active {
    display: block;
}

.qualifications__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.qualifications__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.qualifications__subtitle {
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-1);
}

.qualifications__calendar {
    font-size: var(--small-font-size);
}

.qualifications__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--text-color);
    border-radius: 50%;
}

.qualifications__line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--text-color);
    transform: translate(6px, -7px);
}

@media screen and (max-width: 993px) {
    .qualifications__container {
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 768px) {
    .qualifications__container {
        margin-left: var(--mb-1-5);
        margin-right: var(--mb-1-5);
    }
}

@media screen and (max-width: 576px) {
    .qualifications__sections {
        grid-template-columns: initial;
    }

    .qualifications__button {
        margin: 0 var(--mb-0-75);
    }
}

@media screen and (max-width: 350px) {
    .qualifications__data {
        gap: 0.5rem;
    }
}